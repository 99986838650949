@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh !important;
	min-height: -webkit-fill-available !important;
}

html {
	min-height: -webkit-fill-available !important;
}

h1 {
	color: white;
	font-size: 1.5rem;
	font-weight: 700 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
