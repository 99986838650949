$bg-color: rgba(255, 255, 255, 0.1);

.header {
	background-color: $bg-color;
}

.bottom {
	background-color: $bg-color !important;
	padding: 1rem 0 !important;
}

.layout {
	height: 100%;
}
