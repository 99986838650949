.playerContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.playerButtons {
		display: flex;
		align-items: center;
		justify-content: center;

		margin-bottom: 1rem;

		button {
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				font-size: 1.5rem;
			}
		}

		.playButton {
			span {
				font-size: 2rem;
			}
		}
	}

	.controlsContainer {
		flex: 1;
		max-width: 30vw;
		display: flex;
		align-items: center;
	}
}

.progressContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 75vw;
	max-width: 500px;

	@media screen and (max-width: 800px) {
		padding-bottom: 1rem;
	}
}
