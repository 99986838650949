.rc-slider {
	margin: 0 1rem;
	height: 0.375rem !important;
	padding: 0 !important;
	.rc-slider-rail {
		height: 0.375rem;
		background-color: rgba(255, 255, 255, 0.5);
	}

	.rc-slider-track {
		height: 0.375rem;
		background: white !important;
		transition: all 0.1s ease;
	}

	.rc-slider-handle {
		opacity: 0;
		transition: all 0.1s ease;
		background: lightgreen !important;
		box-shadow: none !important;
		border: none;

		width: 0.6rem;
		height: 0.6rem;
		margin-top: -2px;
		margin-left: 0.1rem;

		&:hover {
			cursor: default;
		}

		&:active {
			cursor: default;
		}
	}

	&:hover {
		.rc-slider-handle {
			opacity: 1;
		}
	}
}

.rc-slider-disabled {
	opacity: 0.5;
}
